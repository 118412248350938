import { calculateTimeHHMMSS } from '@/utils/utils'

export function calculateIdlePositions(locations) {
  const idlePositions = []
  let inIdle = false
  for (let i = 0; i + 1 < locations.length; i++) {
    const p1 = locations[i]
    const p2 = locations[i + 1]
    if (isIdlePosition(p1) && isIdlePosition(p2)) {
      if (!inIdle) {
        idlePositions.push({ ...p1,
          idleTime: new Date(p2.fixTime).getTime() - new Date(p1.fixTime).getTime()
        })
        inIdle = true
      } else {
        idlePositions[idlePositions.length - 1].idleTime += new Date(p2.fixTime).getTime() - new Date(p1.fixTime).getTime()
      }
    } else {
      inIdle = false
    }
  }

  idlePositions.forEach(i => { i.idle_time = calculateTimeHHMMSS(Math.round(i.idleTime / 1000)) })
  return idlePositions
}

export function isIdlePosition(p) {
  const speedThreshold = 3
  return p.attributes.ignition && p.speed < speedThreshold
}

export function getFuelLiters(device) {
  if (device.position.protocol === 'teltonika' && !device.position.attributes.io89) {
    return device.position.attributes.fuel && device.position.attributes.fuel.toFixed(1)
  } else if (device.attributes.fuel_tank_capacity) {
    return Math.round(device.attributes.fuel_tank_capacity * (device.position.fuelLevel / 100))
  }
}

export function getFuelPercentage(device, position) {
  if (position.attributes.io89) {
    return position.attributes.io89
  } else if (device.attributes.fuel_tank_capacity) {
    return Math.round(position.attributes.fuel * 100 / device.attributes.fuel_tank_capacity)
  }
}
